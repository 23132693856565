import styles from "./cta-button.module.css";
import classNames from "classnames";

interface CTAButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  iconSrc?: string;
  children?: React.ReactNode;
}

export const CTAButton = ({
  title,
  children,
  iconSrc,
  className,
  ...rest
}: CTAButtonProps) => {
  return (
    <button
      className={classNames(styles.button, styles.ctaPrimary, className)}
      {...rest}
    >
      {iconSrc && <img className={styles.icon} src={iconSrc} alt="icon" />}
      {title}
      {children}
    </button>
  );
};

interface SecondaryCTAButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  iconSrc?: string;
  children?: React.ReactNode;
}

export const SecondaryCTAButton = ({
  title,
  iconSrc,
  className,
  ...rest
}: SecondaryCTAButtonProps) => {
  return (
    <button
      className={classNames(styles.button, styles.ctaSecondary, className)}
      {...rest}
    >
      {iconSrc && <img className={styles.icon} src={iconSrc} alt="icon" />}
      {title}
    </button>
  );
};

interface LinkCTAButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  children?: React.ReactNode;
}

export const LinkCTAButton = ({
  title,
  className,
  ...rest
}: LinkCTAButtonProps) => {
  return (
    <button
      className={classNames(styles.button, styles.ctaLink, className)}
      {...rest}
    >
      {title}
    </button>
  );
};
