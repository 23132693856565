import { retrieveAccessToken, refreshAccessToken } from "../auth/auth";

enum Environment {
  local,
  staging,
  production,
}

export class HTTPError extends Error {
  body: any;
  statusCode: number;
  constructor(body: any, statusCode: number) {
    super(body);
    this.body = body;
    this.name = "HttpError";
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, HTTPError.prototype); // for extending a built-in class
  }
}

interface ErrorBody {
  message: string;
  error: string;
}

const environment: Environment = Environment.local;

export enum Endpoint {
  signIn = "sign-in",
  signUp = "sign-up",
  signUpConfirm = "sign-up-confirm",
  refreshAuthToken = "refresh-auth-token",
  signedUploadURL = "SignedUploadURL",
  changePassword = "change-password",
  triggerResetPassword = "reset-password",
  confirmResertPassword = "confirm-reset-password",
}

const EndpointRequiresAuth = (endpoint: Endpoint): boolean => {
  switch (endpoint) {
    case Endpoint.signIn:
    case Endpoint.signUpConfirm:
    case Endpoint.signUp:
    case Endpoint.triggerResetPassword:
    case Endpoint.confirmResertPassword:
      return false;
    default:
      return true;
  }
};

const baseURL =
  environment === Environment.local
    ? "http://localhost:8080/"
    : "https://gapqgiinc0.execute-api.eu-north-1.amazonaws.com/";

const baseURLForEndpoint = (endpoint: Endpoint): string => {
  switch (endpoint) {
    default:
      return baseURL;
  }
};

export const endpointURL = (endpoint: Endpoint): string => {
  let url = baseURLForEndpoint(endpoint) + endpoint;
  console.log("Endpoint URL: " + url);
  return url;
};

export const endpointURLWithQueryParameters = (
  endpoint: string,
  params: any | null
): string => {
  if (params) {
    var paramString: string = "";
    (Object.keys(params) as (keyof typeof params)[]).forEach(
      (key, index, array) => {
        const value = params[key];
        if (value !== undefined) {
          paramString =
            paramString +
            String(key) +
            "=" +
            encodeURIComponent(params[key]) +
            "&";
        }
      }
    );
    paramString = paramString.slice(0, -1);
    return endpoint + "?" + paramString;
  }
  return endpoint;
};

export const POST_JSONObjectToEndpoint = async (
  endpoint: Endpoint,
  bodyJSONObject: any
): Promise<any> => {
  const bodyString = JSON.stringify(bodyJSONObject);
  return POST_JSONStringToEndpoint(endpoint, bodyString);
};

export const POST_JSONStringToEndpoint = async (
  endpoint: Endpoint,
  bodyJSONString: any
): Promise<any> => {
  const url = endpointURL(endpoint);
  const options: RequestInit = {
    body: bodyJSONString,
  };
  return await httpClient(url, options, EndpointRequiresAuth(endpoint));
};

export const GET_Endpoint = async (
  endpoint: Endpoint,
  queryParameters?: any | null
): Promise<any> => {
  const url = endpointURL(endpoint);
  const endpointWithParameters = endpointURLWithQueryParameters(
    url,
    queryParameters
  );
  const options: RequestInit = {
    method: "GET",
  };
  return httpClient(
    endpointWithParameters,
    options,
    EndpointRequiresAuth(endpoint)
  );
};

export const DELETE_Endpoint = async (
  endpoint: Endpoint,
  queryParameters: any | null
): Promise<any> => {
  const url = endpointURL(endpoint);
  const endpointWithParameters = endpointURLWithQueryParameters(
    url,
    queryParameters
  );
  const options: RequestInit = {
    method: "DELETE",
  };
  return httpClient(
    endpointWithParameters,
    options,
    EndpointRequiresAuth(endpoint)
  );
};

export const httpClient = async (
  url: string,
  options: RequestInit = {},
  requiresAuth: boolean
): Promise<any> => {
  const headers = new Headers();

  if (requiresAuth) {
    const token = retrieveAccessToken();
    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }
  }
  var requestOptions = {
    method: options.method ?? "POST",
    headers: headers,
    body: options.body,
  };
  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      const errorBody: ErrorBody = await response.json(); // This will parse the stream into JSON
      console.log("Error body:", errorBody); // Now this will log the JSON body

      if (response.status === 401 && errorBody.error === "EXPIRED_TOKEN") {
        refreshAccessToken()
          .then(() => {
            return httpClient(url, options, requiresAuth);
          })
          .catch((error) => {
            // Return the original error, not the refresh token one.
            throw new HTTPError(errorBody, response.status);
          });
      } else if (
        response.status === 400 &&
        errorBody.error === "LIMIT_EXCEEDED"
      ) {
        throw new HTTPError(errorBody, response.status);
      } else {
        console.log("REQUEST LIMIT EXCEEDED");
        const body: ErrorBody = {
          message:
            "There was a problem processing your request, please try again later.",
          error: "LIMIT_EXCEEDED",
        };
        throw new HTTPError(body, response.status);
      }
    } else {
      return await response.json();
    }
  } catch (error: unknown) {
    throw error;
  }
};
