import styles from "./dropdown-menu-button-icon-title.module.css";

interface DropdownMenuButtonIconTitleProps {
  title: string;
  icon?: JSX.Element;
  onClick: () => void;
}

export const DropdownMenuButtonIconTitle = (
  props: DropdownMenuButtonIconTitleProps
) => {
  return (
    <button onClick={props.onClick} className={styles.container}>
      {props.icon && <div className={styles.icon}>{props.icon}</div>}
      <div className={styles.title}>{props.title}</div>
    </button>
  );
};
