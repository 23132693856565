import { useNavigate } from "react-router-dom";
//Leaving the parameter empty in any path will result in the path 'pattern' being used - e.g. ":itemID".
export const usePaths = () => {
  const navigate = useNavigate();

  const home = "/";
  const landing = "/landing";
  const signUp = "/signup";
  const signIn = "/signin";
  const confirmEmail = "/signup-confirm";
  const changePassword = "/change-password";
  const resetPassword = "/reset-password";
  const confirmResetPassword = "/reset-password-confirm";
  const formExamples = "/form-examples";

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const pathMatches = (path1: string, path2: string): boolean => {
    const path1WithoutTrailingPath = path1.replace(/\/$/, "");
    const path2WithoutTrailingPath = path2.replace(/\/$/, "");
    return path1WithoutTrailingPath === path2WithoutTrailingPath;
  };
  return {
    navigateTo,
    pathMatches,
    home,
    landing,
    signUp,
    signIn,
    confirmEmail,
    changePassword,
    resetPassword,
    confirmResetPassword,
    formExamples,
  } as const;
};
