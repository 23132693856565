import styles from "./app-title-bar.module.css";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { TitleBarDropDownMenu } from "./app-title-bar-dropdown-menu";
import { DropdownMenuButtonIconTitle } from "./shared-components/dropdown-menu-button-icon-title";
import { usePaths } from "../paths";
import { useAuth } from "../auth/use-auth";
import { LinkCTAButton } from "../shared-components/cta-button";

export const TitlebarComponentKey_UserDropdownMenu = "userDropdownMenu";
export const TitlebarComponentKey_SignInButton = "signInButton";

export type ToolbarComponentMap = { [key: string]: React.ReactNode };

interface TitleBarContextProps {
  isTitleBarHidden: boolean;
  setIsTitleBarHidden: (isHidden: boolean) => void;
  titleBarTitle: string;
  setTitleBarTitle: (newTitle: string) => void;
  leadingComponents?: ToolbarComponentMap | undefined;
  setLeadingTitleBarComponents: (
    components?: ToolbarComponentMap | undefined
  ) => void;
  trailingComponents?: ToolbarComponentMap | undefined;
  setTrailingTitleBarComponents: (
    components?: ToolbarComponentMap | undefined
  ) => void;
  isMenuButtonHidden: Boolean;
  setIsMenuButtonHidden: (isHidden: boolean) => void;
  accountMenuContent?: ReactNode;
  setAccountMenuContent: (children?: ReactNode) => void;
  setSignInButtonHidden: (isHidden: boolean) => void;
}

// Create the context with a default value
export const TitleBarContext = createContext<TitleBarContextProps>({
  isTitleBarHidden: false,
  setIsTitleBarHidden: () => {},
  titleBarTitle: "Default Title",
  setTitleBarTitle: () => {},
  leadingComponents: undefined,
  setLeadingTitleBarComponents: (
    components?: ToolbarComponentMap | undefined
  ) => {},
  trailingComponents: undefined,
  setTrailingTitleBarComponents: (
    components?: ToolbarComponentMap | undefined
  ) => {},
  isMenuButtonHidden: false,
  setIsMenuButtonHidden: () => {},
  accountMenuContent: undefined,
  setAccountMenuContent: () => {},
  setSignInButtonHidden: (isHidden: boolean) => {},
});

export const useTitleBar = () => {
  const {
    isTitleBarHidden,
    setIsTitleBarHidden,
    titleBarTitle,
    setTitleBarTitle,
    leadingComponents,
    setLeadingTitleBarComponents,
    trailingComponents,
    setTrailingTitleBarComponents,
    isMenuButtonHidden,
    setIsMenuButtonHidden,
    accountMenuContent,
    setAccountMenuContent,
    setSignInButtonHidden,
  } = useContext(TitleBarContext);
  return {
    isTitleBarHidden,
    setIsTitleBarHidden,
    titleBarTitle,
    setTitleBarTitle,
    leadingComponents,
    setLeadingTitleBarComponents,
    trailingComponents,
    setTrailingTitleBarComponents,
    isMenuButtonHidden,
    setIsMenuButtonHidden,
    accountMenuContent,
    setAccountMenuContent,
    setSignInButtonHidden,
  };
};

export const AppTitleBar = (props: { children: ReactNode }) => {
  const [titleBarTitle, setTitleBarTitle] = useState("My App Title");
  const [isTitleBarHidden, setIsTitleBarHidden] = useState<boolean>(false);
  const [isSignInButtonHidden, setIsSignInButtonHidden] =
    useState<boolean>(false);
  const [trailingComponents, setTrailingTitleBarComponents] = useState<
    ToolbarComponentMap | undefined
  >();
  const [leadingComponents, setLeadingTitleBarComponents] = useState<
    ToolbarComponentMap | undefined
  >();
  const [isMenuButtonHidden, setIsMenuButtonHidden] = useState<boolean>(false);
  const [accountMenuContent, setAccountMenuContent] = useState<
    ReactNode | undefined
  >(undefined);
  const { isUserAuthenticated } = useAuth();

  useEffect(() => {
    console.log("Authentication status changed: ", isUserAuthenticated);
    if (!isMenuButtonHidden) {
      if (isUserAuthenticated) {
        setTrailingTitleBarComponents({
          TitlebarComponentKey_UserDropdownMenu: <DefaultUserDropdownMenu />,
        });
      } else {
        if (!isSignInButtonHidden) {
          setTrailingTitleBarComponents({
            TitlebarComponentKey_SignInButton: <TitleBarSignInButton />,
          });
        } else {
          removeTrailingComponent(TitlebarComponentKey_SignInButton);
        }
      }
    } else {
      removeTrailingComponent(TitlebarComponentKey_UserDropdownMenu);
      removeTrailingComponent(TitlebarComponentKey_SignInButton);
    }
  }, [isSignInButtonHidden, isUserAuthenticated, isMenuButtonHidden]);

  const removeTrailingComponent = (key: string) => {
    setTrailingTitleBarComponents((prevComponents) => {
      const updatedComponents = { ...prevComponents }; // Make a shallow copy
      delete updatedComponents.TitlebarComponentKey_SignInButton; // Remove the key entirely
      return updatedComponents; // Update the state with the modified object
    });
  };

  const removeLeadingComponent = (key: string) => {
    setLeadingTitleBarComponents((prevComponents) => {
      const updatedComponents = { ...prevComponents }; // Make a shallow copy
      delete updatedComponents[key]; // Remove the key entirely
      return updatedComponents; // Update the state with the modified object
    });
  };

  return (
    <TitleBarContext.Provider
      value={{
        isTitleBarHidden,
        setIsTitleBarHidden,
        titleBarTitle,
        setTitleBarTitle,
        leadingComponents,
        setLeadingTitleBarComponents,
        trailingComponents,
        setTrailingTitleBarComponents,
        isMenuButtonHidden,
        setIsMenuButtonHidden,
        accountMenuContent,
        setAccountMenuContent,
        setSignInButtonHidden: setIsSignInButtonHidden,
      }}
    >
      <div className={styles.titleBar}>
        <TitleBarContent
          isHidden={isTitleBarHidden}
          title={titleBarTitle}
          leadingComponents={leadingComponents}
          trailingComponents={trailingComponents}
        />
        {props.children}
      </div>
    </TitleBarContext.Provider>
  );
};

interface TitleBarContentsProps {
  isHidden: boolean;
  title: string;
  leadingComponents: ToolbarComponentMap | undefined;
  trailingComponents: ToolbarComponentMap | undefined;
}
const TitleBarContent = (props: TitleBarContentsProps) => {
  console.log("Trailing components: ", props.trailingComponents);
  return (
    <div className={styles.titleBarContent}>
      <div className={styles.titleBarLeftContent}>
        {props.leadingComponents &&
          Object.entries(props.leadingComponents).map(([id, component]) => (
            <div key={id}>{component}</div>
          ))}
      </div>
      <div className={styles.titleBarCenterContent}>
        <div className={styles.title}>{props.title.toUpperCase()}</div>
      </div>
      <div className={styles.titleBarRightContent}>
        {props.trailingComponents &&
          Object.entries(props.trailingComponents).map(([id, component]) => (
            <div key={id}>{component}</div>
          ))}
      </div>
    </div>
  );
};

interface DefaultUserDropdownMenuProps {
  children?: ReactNode;
}

const DefaultUserDropdownMenu = (props: DefaultUserDropdownMenuProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { signOut, isUserAuthenticated } = useAuth();
  const paths = usePaths();

  const onClickSignOut = () => {
    signOut();
    setIsDropdownOpen(false);
    paths.navigateTo(paths.landing);
  };

  const onClickAuthenticate = () => {
    paths.navigateTo(paths.signIn);
    setIsDropdownOpen(false);
  };

  return (
    <TitleBarDropDownMenu
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={(open: boolean) => {
        setIsDropdownOpen(open);
      }}
    >
      {props.children && props.children}
      {isUserAuthenticated ? (
        <DropdownMenuButtonIconTitle
          title="SIGN OUT"
          onClick={onClickSignOut}
        />
      ) : (
        <DropdownMenuButtonIconTitle
          title="SIGN IN/UP"
          onClick={onClickAuthenticate}
        />
      )}
    </TitleBarDropDownMenu>
  );
};

interface TitleBarAccountButtonProps {
  iconName: string;
  onClick: () => void;
}

export const TitleBarAccountButton = (props: TitleBarAccountButtonProps) => {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClick();
  };

  return (
    <button className={styles.titleBarButton} onClick={onClick}>
      <img
        className={styles.titleBarButtonImage}
        src="/icons/icon-person.svg"
        alt="Account menu"
      />
    </button>
  );
};

export const TitleBarSignInButton = () => {
  const paths = usePaths();
  return (
    <LinkCTAButton
      title="SIGN IN / SIGN UP"
      onClick={() => {
        paths.navigateTo(paths.signIn);
      }}
    />
  );
};
