export class AppError extends Error {
  message: string;
  handle: string;
  constructor(message: string, handle: string) {
    super(message);
    this.message = message;
    this.name = "AppError";
    this.handle = handle;
    Object.setPrototypeOf(this, AppError.prototype);
  }
}
