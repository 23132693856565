import React, { ReactNode, useState, useEffect, useRef } from "react";
import { TitleBarAccountButton } from "./app-title-bar";

import styles from "./app-title-bar-dropdown-menu.module.css";

interface TitleBarDropDownMenuProps {
  isDropdownOpen: Boolean;
  setIsDropdownOpen: (open: boolean) => void;
  children: ReactNode;
}

export const TitleBarDropDownMenu = (props: TitleBarDropDownMenuProps) => {
  const containerRef = useRef<HTMLDivElement>(null); // Container ref for button and dropdown
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    props.setIsDropdownOpen(!props.isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        props.setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div ref={containerRef}>
      <TitleBarAccountButton iconName="icon-person" onClick={toggleDropdown} />
      <div
        ref={dropdownRef}
        className={`${styles.dropdown} ${
          props.isDropdownOpen ? "" : styles.hidden
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};
