import React from "react";
import "./App.css";
import { usePaths } from "./BEDROCK/paths";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LandingPage } from "./BEDROCK/pages/landing/landing-page";
import { AuthPage } from "./BEDROCK/pages/auth/auth-page";
import { FormExamples } from "./BEDROCK/pages/examples/form-examples";
import { Home } from "./home";

function App() {
  const paths = usePaths();

  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      {/* <Route path={paths.signUp} element={<AuthPage authContext="signup" />} />
      <Route path={paths.signIn} element={<AuthPage authContext="signin" />} />
      <Route
        path={paths.changePassword}
        element={<AuthPage authContext="changePassword" />}
      />
      <Route
        path={paths.confirmEmail}
        element={<AuthPage authContext="confirm" />}
      />
      <Route
        path={paths.resetPassword}
        element={<AuthPage authContext="resetPassword" />}
      />
      <Route
        path={paths.confirmResetPassword}
        element={<AuthPage authContext="confirmResetPassword" />}
      />
      <Route path={paths.formExamples} element={<FormExamples />} />
      {/* <Route path="*" element={<NotFound />} /> 
      <Route path={paths.landing} element={<LandingPage />} /> */}
    </Routes>
  );
}

export default App;
