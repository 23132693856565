import { POST_JSONObjectToEndpoint, Endpoint, HTTPError } from "../api/api";
import { jwtDecode } from "jwt-decode";
import { AppError } from "../AppError";

const signUpUser = async (email: string, password: string) => {
  const params = { email: email, password: password };
  return POST_JSONObjectToEndpoint(Endpoint.signUp, params).catch((error) => {
    const defaultErrorMessage =
      error.body?.message || "There was a problem signing in.";
    throw new HTTPError(defaultErrorMessage, 401);
  });
};

const signInUser = async (email: string, password: string) => {
  const params = { email: email, password: password };
  return POST_JSONObjectToEndpoint(Endpoint.signIn, params)
    .then((json) => {
      const accessToken = json.auth_token;
      const refreshToken = json.refresh_token;
      saveAccessToken(accessToken);
      saveRefreshToken(refreshToken);
    })
    .catch((error) => {
      if (error.statusCode === 422) {
        const errorMessage =
          error.body?.message || "Incorrect email or password.";
        throw new HTTPError(errorMessage, 422);
      }

      const defaultErrorMessage =
        error.body?.message || "There was a problem signing in.";
      throw new HTTPError(defaultErrorMessage, 401);
    });
};

const signOutUser = () => {
  deleteToken();
};

const refreshAccessToken = async () => {
  const refreshToken = retrieveRefreshToken();
  if (!refreshAccessToken) {
    signOutUser();
    throw new AppError("Your session has expired.", "NO_REFRESH_TOKEN");
  }
  const params = { refresh_token: refreshToken };
  return POST_JSONObjectToEndpoint(Endpoint.refreshAuthToken, params)
    .then((json) => {
      const token = json.auth_token;
      const refreshToken = json.refresh_token;
      if (token && refreshToken) {
        saveAccessToken(token);
        saveRefreshToken(refreshToken);
      } else {
        throw new AppError(
          "Your session has expired.",
          "FAILED_TO_REFRESH_TOKEN"
        );
      }
    })
    .catch((error) => {
      throw error;
    });
};

const confirmUser = async (
  email: string,
  password: string,
  confirmationCode: string
) => {
  const params = {
    email: email,
    password: password,
    code: confirmationCode,
  };
  return POST_JSONObjectToEndpoint(Endpoint.signUpConfirm, params)
    .then((json) => {
      const accessToken = json.auth_token;
      const refreshToken = json.refresh_token;
      saveAccessToken(accessToken);
      saveRefreshToken(refreshToken);
    })
    .catch((error) => {
      throw new HTTPError(error.messaage, error.statusCode);
    });
};

const changeUserPassword = async (
  previousPassword: string,
  newPassword: string
) => {
  const params = {
    new_password: newPassword,
    previous_password: previousPassword,
  };
  return POST_JSONObjectToEndpoint(Endpoint.changePassword, params);
};

const triggerUserPasswordReset = async (email: string) => {
  const params = { email: email };
  return POST_JSONObjectToEndpoint(Endpoint.triggerResetPassword, params);
};

const confirmUserPasswordReset = async (
  email: string,
  code: string,
  newPassword: string
) => {
  const params = { email: email, code: code, new_password: newPassword };
  return POST_JSONObjectToEndpoint(Endpoint.confirmResertPassword, params);
};

// const validateEmail = (email: string): boolean => {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailRegex.test(email);
// };

// const validatePassword = (password: string): boolean => {
//   /*
//     Minimum 8 characters.
//     Contains at least 1 number.
//     Contains at least 1 special character.
//     Contains at least 1 uppercase letter.
//     Contains at least 1 lowercase letter.
//     */
//   const passwordRegex =
//     /^(?=.*\d)(?=.*[!@#$;%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
//   return passwordRegex.test(password);
// };

const isUserAuthenticated = (): boolean => {
  return retrieveAccessToken() != null;
};

const userIDFromToken = (): string | undefined => {
  const token = retrieveAccessToken();
  if (token) {
    const id = getUserIdFromToken(token);
    return id;
  }
};

const saveAccessToken = (token: string) => {
  localStorage.setItem("accessToken", JSON.stringify(token));
};

const saveRefreshToken = (token: string) => {
  localStorage.setItem("refreshToken", JSON.stringify(token));
};

const retrieveAccessToken = (): string | null => {
  const savedToken = localStorage.getItem("accessToken");
  if (savedToken && savedToken !== "undefined") {
    return JSON.parse(savedToken);
  }
  return null;
};

const retrieveRefreshToken = (): string | null => {
  const savedToken = localStorage.getItem("refreshToken");
  if (savedToken) {
    return JSON.parse(savedToken);
  }
  return null;
};

const deleteToken = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

function getUserIdFromToken(token: string): string | undefined {
  try {
    const decodedToken = jwtDecode(token);
    // Accessing the user ID (sub)
    if (decodedToken.sub) {
      return decodedToken.sub;
    } else {
      console.log("User ID not found in the token.");
    }
  } catch (error) {
    console.error("Error decoding token:", error);
  }
}

export {
  signUpUser,
  signInUser,
  signOutUser,
  confirmUser,
  changeUserPassword,
  triggerUserPasswordReset,
  confirmUserPasswordReset,
  refreshAccessToken,
  isUserAuthenticated,
  retrieveAccessToken,
  userIDFromToken,
};

var cachedEmail: string | undefined;
