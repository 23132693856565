import { useTitleBar } from "./BEDROCK/title-bar/app-title-bar";
import styles from "./base-styles.module.css";
interface HomeProps {}

export const Home = (props: HomeProps) => {
  const {
    setTitleBarTitle,
    setIsMenuButtonHidden,
    setLeadingTitleBarComponents,
  } = useTitleBar();

  setTitleBarTitle("");
  setIsMenuButtonHidden(true);
  setLeadingTitleBarComponents({
    leadingTitleBarContent: <LeadingTitleBarContent />,
  });

  return <div className={styles.titleBarMainHeader}></div>;
};

const LeadingTitleBarContent = () => {
  return <div className={styles.titleBarMainHeader}></div>;
};
