import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  signInUser,
  signUpUser,
  signOutUser,
  isUserAuthenticated as isUserAuthentictedFromToken,
  confirmUser,
  changeUserPassword,
  triggerUserPasswordReset,
  confirmUserPasswordReset,
} from "./auth";
import { AppError } from "../AppError";

// Define the type for authentication state
export type AuthenticationState =
  | "authenticated"
  | "unauthenticated"
  | "unconfirmed";

// Define the context interface
interface AuthContextType {
  isUserAuthenticated: boolean;
  authenticationState: AuthenticationState | undefined;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  // Email should be populated in the confirmation fields from this hook when the user signs up.
  confirmEmail: (
    email: string,
    password: string,
    code: string
  ) => Promise<void>;
  emailPendingConfirmation: string | undefined;
  passwordPendingConfirmation: string | undefined;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  triggerPasswordReset: (email: string) => Promise<void>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
  signOut: () => void;
  isProcessing: boolean;
}

// Create the AuthContext with a default value of undefined
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// AuthProvider component to wrap around components
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // Initialize the state for authentication, starting with "unauthenticated"
  const [authState, setAuthState] =
    useState<AuthenticationState>("unauthenticated");
  const [emailPendingConfirmation, setEmailPendingConfirmation] = useState<
    string | undefined
  >();
  const [emailPendingPasswordReset, setEmailPendingPasswordReset] = useState<
    string | undefined
  >();

  const [passwordPendingConfirmation, setPasswordPendingConfirmation] =
    useState<string | undefined>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (isUserAuthentictedFromToken()) {
      setAuthState("authenticated");
    } else {
      setAuthState("unauthenticated");
    }
  }, []);

  // Define the signIn function
  const signIn = async (email: string, password: string) => {
    try {
      setIsProcessing(true);
      await signInUser(email, password);
      setAuthState("authenticated");
      setIsProcessing(false);
    } catch (error) {
      setAuthState("unauthenticated");
      setIsProcessing(false);
      throw error;
    }
  };

  // Define the signUp function
  const signUp = async (email: string, password: string) => {
    try {
      setIsProcessing(true);
      await signUpUser(email, password);
      setEmailPendingConfirmation(email);
      setPasswordPendingConfirmation(password);
      setAuthState("unconfirmed");
      setIsProcessing(false);
    } catch (error) {
      console.error("Sign-up failed:", error);
      setAuthState("unauthenticated");
      throw error;
    }
  };

  const confirmEmail = async (
    email: string,
    password: string,
    code: string
  ) => {
    try {
      setIsProcessing(true);
      await confirmUser(email, password, code);
      // User should be automatically signed in on the back end after confirmation,
      // which is why we pass up the password.
      setAuthState("authenticated");
      setIsProcessing(false);
    } catch (error) {
      setAuthState("unauthenticated");
      setIsProcessing(false);
      throw error;
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    setIsProcessing(true);
    try {
      await changeUserPassword(oldPassword, newPassword);
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      throw error;
    }
  };

  const triggerPasswordReset = async (email: string) => {
    setIsProcessing(true);
    try {
      await triggerUserPasswordReset(email);
      setEmailPendingPasswordReset(email);
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      throw error;
    }
  };

  const confirmPasswordReset = async (code: string, newPassword: string) => {
    if (emailPendingPasswordReset === undefined) {
      throw new AppError(
        "No email found for password reset confirmation",
        "NO_EMAIL_FOR_PASSWORD_RESET"
      );
    }
    setIsProcessing(true);
    try {
      await confirmUserPasswordReset(
        emailPendingPasswordReset,
        code,
        newPassword
      );
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      throw error;
    }
  };

  const signOut = () => {
    signOutUser();
    setAuthState("unauthenticated");
  };

  const isUserAuthenticated = authState === "authenticated";

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated,
        authenticationState: authState,
        signIn,
        signUp,
        confirmEmail,
        emailPendingConfirmation,
        passwordPendingConfirmation,
        triggerPasswordReset,
        confirmPasswordReset,
        changePassword,
        signOut,
        isProcessing,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
